* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  /* user-select: none; */
}

:root {
  --_five: 0.5em;
  --one: 1em;
  --one_five: 1.5em;
  --two: 2em;
  --three: 3em;
  --green: #355d39;
  --green_variant: #349966;
  --grey: #f4f4f7;
  --red: #c50e0e;
}


/* button:active, button:focus, button:visited{
  outline: none;
} */

button.bg-green-var[disabled] {
  background-color: #7daa94c5 !important;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link:visited {
  color: inherit;
}

.none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.hidden-input {
  height: 0px;
  width: 0px;
  margin: 0px;
  padding: 0px;
  border: none;
}

.button {
  background: none;
  border: none;
}

.m-0 {
  margin: 0;
}

.m-0-10 {
  margin: 0 10px;
}

.m-0-20 {
  margin: 0 20px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.p-0 {
  padding: 0;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.m-1 {
  margin: var(--one);
}

.m-2 {
  margin: var(--two);
}

/* Top */
.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: var(--one);
}

.pt-1 {
  padding-top: var(--one);
}

.mt-2 {
  margin-top: var(--two);
}

.mt-0\.5 {
  margin-top: var(--_five);
}

/* Bottom */
.mb-0 {
  margin-bottom: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-0\.5 {
  margin-bottom: var(--_five);
}

.mb-1 {
  margin-bottom: var(--one);
}

.mb-2 {
  margin-bottom: var(--two);
}

.mb-3 {
  margin-bottom: var(--three);
}

/* Right */

.mr-0 {
  margin-right: 0;
}

.mr-0\.2 {
  margin-right: 2px;
}

.mr-0\.5 {
  margin-right: 0.5em;
}

.mr-1 {
  margin-right: var(--one);
}

.mr-2 {
  margin-right: var(--two);
}

/* Left */

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 1em;
}

.ml-2 {
  margin-left: 1em;
}

@media screen and (min-width: 500px) {
  .m-container {
    max-width: 500px;
    margin: 0 auto !important;
  }
}

.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 5px;
}

.b-1-g {
  border: 1px solid lightgrey;
}

/* Others */

.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.t-0 {
  top: 0;
}

.r-0 {
  right: 0;
}

.l-0 {
  left: 0;
}

.b-0 {
  bottom: 0;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-col {
  flex-direction: column;
}

.flex-full {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-around {
  justify-content: space-around;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.self-stretch {
  align-self: stretch;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* Font Sizes */

.size-inherit {
  font-size: inherit;
}

.size-xs {
  font-size: 0.6em;
}

.size-sm {
  font-size: 0.8em;
}

.size-md {
  font-size: 1em;
}

.size-mb {
  font-size: 1.1em;
}

.size-big {
  font-size: 1.3em;
}

.grey {
  color: grey;
}

.green {
  color: var(--green);
}

.red {
  color: var(--red);
}

.white {
  color: white;
}

.black {
  color: black;
}

.bg-grey,
.bg-gray {
  background-color: var(--grey) !important;
}

.bg-green {
  background-color: var(--green) !important;
}

.bg-green-var {
  background-color: var(--green_variant) !important;
}

.bg-white {
  background-color: white;
}

.bg-black {
  background-color: black;
}

.bg-blue {
  background-color: #203479;
}

.bg-yellow {
  background-color: #f9f990;
}

/* width */
.w-1\/2 {
  width: 50%;
}

.w-49 {
  width: calc(50% - 0.5em);
}

.w-1\/3 {
  width: 33%;
}

.w-1\/4 {
  width: 25%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-inherit {
  height: inherit;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.und {
  text-decoration: underline;
}

.flex.two-per-row > .w-1\/2 {
  padding-right: 2.5px;
}

.flex.two-per-row > .w-1\/2:nth-of-type(even) {
  padding-right: 0;
  padding-left: 2.5px;
}






























.blinking-cursor {
  font-weight: 100;
  font-size: 30px;
  position: relative;
  bottom: 2px;
  color: #2e3d48;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
